import moment from 'moment'

export default {
  filters: {
    toLocaleString (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    amountReceived (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return (value - (value * parseFloat(process.env.VUE_APP_SERVICE_FEE))).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    subtractFee (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value * process.env.VUE_APP_SERVICE_FEE
      }
    },
    cashoutFee (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return (value * process.env.VUE_APP_CASHOUT_FEE + 100).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    localDate: function (value) {
      return value ? moment(value).format('MMM DD, YYYY') : ''
    }
  }
}
