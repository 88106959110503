<template>
  <div>
    <p class="display-4 font-weight-thin">404</p>
    <p class="display-1 font-weight-bold error--text">Page Not Found</p>
    <v-btn class="mt-5" text @click="goBack"><v-icon class="pr-2">keyboard_backspace</v-icon>Go Back</v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
