import Api from '@/services/Api'

export default {
  postLegalAdvice (params) {
    return Api().post('/api/clientProblems', params)
  },
  getPendingProblems (params) {
    return Api().get(`/api/clientProblems/pendings?page=${params.page}`, params)
  },
  getPendingProblem (params) {
    return Api().get('/api/clientProblems/pendings/' + params.problemId)
  },
  cancelProblem (params) {
    return Api().patch(`/api/clientProblems/cancel/${params.problemId}`)
  }
}
