<template>
  <v-container :py-0="!$vuetify.breakpoint.smAndDown" :pa-0="$vuetify.breakpoint.smAndDown" :my-5="$vuetify.breakpoint.mdAndUp">
    <v-row justify="center" no-gutters>
      <v-col v-if="!notFound" cols="12" md="10" lg="8" xl="6">
        <v-card class="mb-2">
          <v-row v-if="job.cancelled && !isFetching" align="center" class="pb-3 pt-0" no-gutters>
            <v-col cols="12" class="pa-2 error text-center">
              <v-icon small class="pr-2 text-center" color="white">error</v-icon>
              <span class="white--text body-2">This task has been cancelled.</span>
            </v-col>
          </v-row>
          <v-row v-if="!isFetching" class="pa-3" no-gutters>
            <v-col v-if="job.client" cols="12">
              <v-row v-if="!job.cancelled" align="center" class="pb-0" no-gutters>
                <v-col cols="5">
                  <span :class="$vuetify.breakpoint.smAndDown ? 'caption grey--text' : 'grey--text body-2'">Posted {{ job.createdAt | momentFromNow }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <!-- <v-col cols="6" class="text-right">
                  <span :class="$vuetify.breakpoint.smAndDown ? 'caption grey--text' : 'grey--text body-2'">Time Frame: <span class="black--text font-weight-medium">{{ job.daysToFinish }} {{ job.daysToFinish > 1 ? 'days' : 'day' }}</span></span>
                </v-col> -->
                <v-col cols="1" v-if="!$store.getters['user/user'].user.isLawyer" class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="cancelJob()">
                        <v-icon color="error">delete</v-icon>
                        <v-list-item-title class="pl-2">Cancel Task</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-row no-gutters>
                  <v-col cols="2" sm="1" class="pt-3 mr-1">
                    <v-img
                      v-if="$store.getters['user/user'].user.isLawyer"
                      :src="job.isAnonymous || !job.client.imageUrl ? 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png' : job.client.imageUrl"
                      aspect-ratio="1"
                      alt="avatar"
                      style="border-radius: 8%"
                    ></v-img>
                    <v-img
                      v-else
                      :src="$store.getters['user/user'].user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                      aspect-ratio="1"
                      alt="avatar"
                      style="border-radius: 8%"
                    ></v-img>
                  </v-col>
                  <v-col cols="9" sm="9" :class="{'pl-2': $vuetify.breakpoint.smAndUp }" class="pb-3">
                    <v-list-item-content :class="{ 'pl-2 pb-1': $vuetify.breakpoint.xsOnly, 'pb-0 mb-0': $vuetify.breakpoint.mdAndUp }">
                      <span v-if="$store.getters['user/user'].user.isLawyer" :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-medium' : 'body-2 font-weight-medium mb-0'">{{ job.isAnonymous ? 'Client prefers to be anonymous.' : job.client.fullName }}</span>
                      <span v-if="!$store.getters['user/user'].user.isLawyer" :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-medium' : 'body-2 font-weight-medium'">{{ job.client.fullName }}</span>
                      <span v-if="!job.isAnonymous" class="caption grey--text">{{ job.client.city }}, {{ job.client.province }}</span>
                      
                    </v-list-item-content>
                    <v-chip dark small
                      :class="{
                        'catLA ml-0': job.problemType == 'Legal Advice',
                        'catLA ml-1': job.problemType == 'Legal Advice' && $vuetify.breakpoint.smAndDown,
                        'ml-0 pt-0 catLD': job.problemType == 'Legal Documentation',
                        'ml-0 catNO': job.problemType == 'Notarization',
                        'ml-0 catLR': job.problemType == 'Legal Representation'
                        }"
                      >{{ job.problemType }}</v-chip>
                  </v-col>
                </v-row>
                
              </v-row>
            </v-col>
            <v-flex :offset-xs1="$vuetify.breakpoint.smAndUp" :xs11="$vuetify.breakpoint.smAndUp" :pl-3="$vuetify.breakpoint.smAndUp">
              <span :class="$vuetify.breakpoint.mdAndUp ? 'subheading font-weight-medium d-flex mb-1' : 'font-weight-medium d-flex mb-1 body-2'">{{ job.title }}</span>
              <div v-if="!$store.getters['user/user'].user.isLawyer">
                <p v-for="line in job.description.split('\n')" :key="line.key" :class="$vuetify.breakpoint.mdAndUp ? 'text-md-body-2 font-weight-medium' : 'body-2 font-weight-medium'">{{ line }}</p>
              </div>
            </v-flex>
          </v-row>
          <v-layout v-else justify-center>
            <v-flex xs12 pa-3>
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true"/>
                <content-placeholders-text :lines="5"/>
              </content-placeholders>
            </v-flex>
          </v-layout>
        </v-card>
        <LawyerBid v-if="$store.getters['user/user'].user.isLawyer" :client="job.client" :isCancelled="job.cancelled" />
        <Bidders v-else :problemTitle="job.title" :isAnonymous="job.isAnonymous" />
      </v-col>
      <v-flex v-else xs12 md10 lg8 xl6 text-xs-center>
        <NoData />
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import ClientProblemService from '@/services/ClientProblemService'
import Bidders from '@/components/OpenJobsComponents/Bidders'
import LawyerBid from '@/components/OpenJobsComponents/LawyerBid'
import momentFromNow from '@/mixins/momentFromNowMixin'
import JobService from '@/services/JobService'
import NoData from '@/views/errors/NoData'

export default {
  name: 'OpenJob',
  components: {
    Bidders,
    LawyerBid,
    NoData
  },
  mixins: [
    momentFromNow
  ],
  data () {
    return {
      isFetching: true,
      notFound: false,
      job: {
        client: {
          email: ''
        },
        title: '',
        description: ''
      }
    }
  },
  methods: {
    getPendingJob () {
      JobService.getPendingJob({
        jobId: this.$route.params.problemId
      })
        .then(res => {
          this.job = res.data.job
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.notFound = true
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    getProposals () {
      this.$store.dispatch('proposals/getLAProposals', this.$route.params.problemId)
        .catch(err => {
          this.$swal.insertQueueStep({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    },
    cancelJob () {
      this.$swal.queue([{
        text: 'Are you sure you want to cancel this task?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: this.$vuetify.theme.primary,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          this.$swal.disableButtons()
          return ClientProblemService.cancelProblem({problemId: this.$route.params.problemId})
            .then(res => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Succesfully cancelled the pending task.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
              this.$router.push('/post-problem')
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  created () {
    this.getPendingJob()
    this.getProposals()
  }
}
</script>
