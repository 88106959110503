export default {
  filters: {
    calculateRating: function (value) {
      if (value.length) {
        const length = value.length
        let sum = 0
        for (var i = 0; i < length; i++) {
          sum = sum + value[i].rating
        }
        sum = (sum / length).toFixed(1)
        return parseFloat(sum)
      } else {
        return 0
      }
    }
  }
}
