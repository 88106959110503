<template>
  <div>
    <v-card v-if="$store.getters['user/user'].user.isLawyerVerified && $store.getters['user/user'].user.isVerified" class="mt-2">
      <v-card-actions v-if="proposalCount > 0" class="body-2 px-5 py-3">
        <p :class="!$vuetify.breakpoint.mdAndUp ? 'body-2 mb-0' : 'mb-0'">{{ proposalCount }} lawyer(s) are bidding on this task.</p>
        <v-spacer></v-spacer>
        <p class="mb-0">Average bid: {{ averageAmount | toLocaleString }}</p>
      </v-card-actions>
      <div v-else class="body-2 px-4 py-3">No lawyer(s) is/are bidding on this task.</div>
      <v-divider></v-divider>
      <v-container class="grid-list-md">
        <!-- <v-flex xs12 primary pa-3 mb-4 :mx-4="$vuetify.breakpoint.smAndUp">
          <span :class="$vuetify.breakpoint.mdAndUp ? 'white--text' : 'white--text caption'"><span class="font-weight-bold">Note: </span>A minimal 1% cashout fee plus P100.00 service fee shall be charged on every withdrawal made.</span>
        </v-flex> -->
        <v-form ref="createBidForm">
        <v-layout row wrap :px-4="$vuetify.breakpoint.smAndUp" align-content-center >
          <v-flex xs12 class="pa-0">
            <span class="body-2">Tell the client why he/she should pick you by giving a brief description of yourself, your skills and experience, as well as your approach to the client’s queries.</span>
            <v-textarea
              outlined
              auto-grow
              counter="1500"
              class="pt-3"
              label="Proposal"
              :rules="proposalMessageRule"
              v-model="proposal.message"
            ></v-textarea>
          </v-flex>
          <v-row no-gutters>
          <v-col cols="4">
              <!-- <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on }">
                <v-checkbox hide-details label="Free Advice" v-on="on" @change="freeProposal"></v-checkbox>
              </template>
              <span>If chosen, your credit will be refunded after you successfully finished the job.</span>
            </v-tooltip> -->
            <v-checkbox @change="freeProposal" class="my-0">
              <template v-slot:label>
                <span class="body-2">Free Advice</span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="8">
            <v-text-field
              :rules="proposal.isFree ? isFreeRule : amountRule"
              :disabled="proposal.isFree"
              v-model.number="proposal.amount"
              label="Amount"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
          </v-row>
        </v-layout>
        </v-form>
        <v-layout row wrap :px-4="$vuetify.breakpoint.smAndUp" :justify-end="$vuetify.breakpoint.smAndUp">
          <!-- <v-card-actions>
            <span class="subheading font-weight-bold pr-1">{{ $store.getters['user/user'].user.credits }}</span> <span class="grey--text pr-3">Credits left</span>
            <v-btn large depressed color="success" to="/topup" v-if="$store.getters['user/user'].user.credits === 0">Buy Credits</v-btn>
            <v-btn 
              large 
              depressed
              color="primary" 
              :disabled="$store.getters['user/user'].user.credits > 0 ? false : true"
              :loading="isSubmitting"
              @click="createProposal"
              >Submit Proposal
            </v-btn>
            <v-btn 
              block
              large 
              depressed
              color="primary" 
              :loading="isSubmitting"
              @click="createProposal()"
              >Submit Proposal
            </v-btn>
          </v-card-actions> -->
          <v-btn
              large 
              depressed
              color="primary"
              :block="!$vuetify.breakpoint.mdAndUp" 
              :loading="isSubmitting"
              @click="createProposal()"
              >Submit Proposal
            </v-btn>
        </v-layout>
      </v-container>
    </v-card>
    <v-card v-else class="mt-2 py-3 dropShadow">
      <v-card-actions class="justify-center">
        <v-icon class="pr-2" color="primary">error</v-icon>
        <span class="ml-2">You will be able to submit proposals once your profile is approved and your Email is verified.</span>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import toLocaleString from '@/mixins/toLocaleStringMixin'
import io from 'socket.io-client'

export default {
  mixins: [
    toLocaleString
  ],
  props: [
    'averageAmount',
    'lawyerCount'
  ],
  props: {
    client: {
      type: Object,
      required: true
    },
    averageAmount: {
      type: Number
    },
    proposalCount: {
      type: Number
    }
  },
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isSubmitting: false,
      proposal: {
        amount: 200,
        isFree: false
      },
      proposalMessageRule: [
        (v) => !!v || 'Please enter your proposal.',
        (v) => (v && v.length >= 100) || 'Please enter at least 100 characters.',
        (v) => (v && v.length <= 1500) || 'Please enter at most 1500 characters.'
      ],
      amountRule: [
        (v) => !!v || 'Please enter an amount.',
        (v) => v >= 200 || 'Minimum amount is ₱200.'
      ],
      isFreeRule: []
    }
  },
  methods: {
    createProposal () {
      if (this.$refs.createBidForm.validate()) {
        this.isSubmitting = true
        this.proposal.client = this.client._id
        this.$store.dispatch('proposals/ADD_LA_PROPOSALS', this.proposal)
          .then(res => {
            this.$store.dispatch('jobs/setBidsJob', { jobId: this.$route.params.problemId })
            this.socket.emit('sendProposal', {
              notifType: 'Legal Advice Proposal',
              count: 1,
              problem: {
                _id: this.$route.params.problemId
              },
              email: this.client.email
            })
            this.$emit('lawyer-submitted')
            this.$swal({
              type: 'success',
              text: 'Your proposal has been sent to the client.',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
            this.proposal = {
              amount: 0
            }
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              text: 'There is a problem with the server',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    },
    freeProposal () {
      this.proposal.isFree = !this.proposal.isFree
      if (this.proposal.isFree) {
        this.proposal.amount = 0
      } else {
        this.proposal.amount = 200
      }
    },
    addFollowUp (event) {
      this.proposal.followUps = parseInt(event.charAt(0))
    }
  },
  filters: {
    subtractFee (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        value = value * process.env.VUE_APP_SERVICE_FEE
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    totalEarning (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        value = value - (value * process.env.VUE_APP_SERVICE_FEE)
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    }
  },
  mounted () {
    this.proposal.problem = this.$route.params.problemId
    this.proposal.isFree = false
    this.proposal.lawyer = this.$store.getters['user/user'].user._id
  },
  watch: {
    'proposal.amount' () {
      this.proposal.earning = this.proposal.amount - (this.proposal.amount * process.env.VUE_APP_SERVICE_FEE)
      this.proposal.fee = parseFloat((this.proposal.amount * process.env.VUE_APP_SERVICE_FEE).toFixed(2))
    }
  }
}
</script>
