<template>
  <v-dialog v-model="show" max-width="500px">
    <v-sheet>
      <v-card-actions>
        <v-btn color="primary" text @click.stop="show=false">Close</v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: ['visible'],
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style>

</style>
