var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"py-0":!_vm.$vuetify.breakpoint.smAndDown,"pa-0":_vm.$vuetify.breakpoint.smAndDown,"my-5":_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[(!_vm.notFound)?_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8","xl":"6"}},[_c('v-card',{staticClass:"mb-2"},[(_vm.job.cancelled && !_vm.isFetching)?_c('v-row',{staticClass:"pb-3 pt-0",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"pa-2 error text-center",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"pr-2 text-center",attrs:{"small":"","color":"white"}},[_vm._v("error")]),_c('span',{staticClass:"white--text body-2"},[_vm._v("This task has been cancelled.")])],1)],1):_vm._e(),(!_vm.isFetching)?_c('v-row',{staticClass:"pa-3",attrs:{"no-gutters":""}},[(_vm.job.client)?_c('v-col',{attrs:{"cols":"12"}},[(!_vm.job.cancelled)?_c('v-row',{staticClass:"pb-0",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{class:_vm.$vuetify.breakpoint.smAndDown ? 'caption grey--text' : 'grey--text body-2'},[_vm._v("Posted "+_vm._s(_vm._f("momentFromNow")(_vm.job.createdAt)))])]),_c('v-spacer'),(!_vm.$store.getters['user/user'].user.isLawyer)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,3491234933)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.cancelJob()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")]),_c('v-list-item-title',{staticClass:"pl-2"},[_vm._v("Cancel Task")])],1)],1)],1)],1):_vm._e()],1):_vm._e(),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3 mr-1",attrs:{"cols":"2","sm":"1"}},[(_vm.$store.getters['user/user'].user.isLawyer)?_c('v-img',{staticStyle:{"border-radius":"8%"},attrs:{"src":_vm.job.isAnonymous || !_vm.job.client.imageUrl ? 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png' : _vm.job.client.imageUrl,"aspect-ratio":"1","alt":"avatar"}}):_c('v-img',{staticStyle:{"border-radius":"8%"},attrs:{"src":_vm.$store.getters['user/user'].user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png',"aspect-ratio":"1","alt":"avatar"}})],1),_c('v-col',{staticClass:"pb-3",class:{'pl-2': _vm.$vuetify.breakpoint.smAndUp },attrs:{"cols":"9","sm":"9"}},[_c('v-list-item-content',{class:{ 'pl-2 pb-1': _vm.$vuetify.breakpoint.xsOnly, 'pb-0 mb-0': _vm.$vuetify.breakpoint.mdAndUp }},[(_vm.$store.getters['user/user'].user.isLawyer)?_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'font-weight-medium' : 'body-2 font-weight-medium mb-0'},[_vm._v(_vm._s(_vm.job.isAnonymous ? 'Client prefers to be anonymous.' : _vm.job.client.fullName))]):_vm._e(),(!_vm.$store.getters['user/user'].user.isLawyer)?_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'font-weight-medium' : 'body-2 font-weight-medium'},[_vm._v(_vm._s(_vm.job.client.fullName))]):_vm._e(),(!_vm.job.isAnonymous)?_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm.job.client.city)+", "+_vm._s(_vm.job.client.province))]):_vm._e()]),_c('v-chip',{class:{
                      'catLA ml-0': _vm.job.problemType == 'Legal Advice',
                      'catLA ml-1': _vm.job.problemType == 'Legal Advice' && _vm.$vuetify.breakpoint.smAndDown,
                      'ml-0 pt-0 catLD': _vm.job.problemType == 'Legal Documentation',
                      'ml-0 catNO': _vm.job.problemType == 'Notarization',
                      'ml-0 catLR': _vm.job.problemType == 'Legal Representation'
                      },attrs:{"dark":"","small":""}},[_vm._v(_vm._s(_vm.job.problemType))])],1)],1)],1)],1):_vm._e(),_c('v-flex',{attrs:{"offset-xs1":_vm.$vuetify.breakpoint.smAndUp,"xs11":_vm.$vuetify.breakpoint.smAndUp,"pl-3":_vm.$vuetify.breakpoint.smAndUp}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'subheading font-weight-medium d-flex mb-1' : 'font-weight-medium d-flex mb-1 body-2'},[_vm._v(_vm._s(_vm.job.title))]),(!_vm.$store.getters['user/user'].user.isLawyer)?_c('div',_vm._l((_vm.job.description.split('\n')),function(line){return _c('p',{key:line.key,class:_vm.$vuetify.breakpoint.mdAndUp ? 'text-md-body-2 font-weight-medium' : 'body-2 font-weight-medium'},[_vm._v(_vm._s(line))])}),0):_vm._e()])],1):_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-3":""}},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":5}})],1)],1)],1)],1),(_vm.$store.getters['user/user'].user.isLawyer)?_c('LawyerBid',{attrs:{"client":_vm.job.client,"isCancelled":_vm.job.cancelled}}):_c('Bidders',{attrs:{"problemTitle":_vm.job.title,"isAnonymous":_vm.job.isAnonymous}})],1):_c('v-flex',{attrs:{"xs12":"","md10":"","lg8":"","xl6":"","text-xs-center":""}},[_c('NoData')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }