<template>
  <div v-if="!isFetching">
    <v-card :class="hasSubmitted || hadBid ? 'mt-2' : 'd-none'">
      <v-row align="center" no-gutters>
        <v-col v-if="!proposals.length && !isCancelled" class="text-md-subtitle-1 pa-2 text-center" cols="12">There are no bids yet.</v-col>
        <!-- <v-col v-if="proposals.length && !isCancelled" cols="12" no-gutters>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="9">
              <span :class="!$vuetify.breakpoint.mdAndUp ? 'body-2' : ''">{{ proposals.length }} lawyer(s) are bidding on this task.</span>
            </v-col>
            <v-col cols="3" class="pa-0">
              <span v-if="!$vuetify.breakpoint.smAndDown" class="grey--text">Average bid: <span class="primary--text font-weight-medium">{{ averageBidAmount | toLocaleString }}</span></span>
              <div v-else class="text-center">
                <p class="mb-0 primary--text font-weight-medium body-2">{{ averageBidAmount | toLocaleString }}</p>
                <p class="mb-0 caption grey--text">Average bid</p>
              </div>
            </v-col> 
          </v-row>
        </v-col> -->
        <v-col v-if="proposals.length && !isCancelled" cols="12" class="pa-3">
          <v-card-actions class="pa-0">
            <span :class="!$vuetify.breakpoint.mdAndUp ? 'caption' : ''">{{ proposals.length }} lawyer(s) are bidding on this task.</span>
            <v-spacer></v-spacer>
            <span v-if="!$vuetify.breakpoint.smAndDown" class="grey--text">Average bid: <span class="primary--text font-weight-medium">{{ averageBidAmount | toLocaleString }}</span></span>
            <div v-else class="text-center">
              <p class="mb-0 primary--text font-weight-medium body-2">{{ averageBidAmount | toLocaleString }}</p>
              <p class="mb-0 caption grey--text">Average bid</p>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="proposal != null"  align="center" class="pa-3" no-gutters>
        <v-col cols="2" sm="1" class="pa-0">
          <v-img
            :src="user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
            alt="avatar"
            style="border-radius: 8%"
          ></v-img>
        </v-col>
        <v-col cols="10" sm="5" class="pl-2 pb-1">
          <span :class="$vuetify.breakpoint.mdAndUp ? ' font-weight-medium' : 'body-2 font-weight-medium'">{{ user.fullName }}</span>
          <v-card-actions v-if="user.reviews.length > 0" class="pa-0">
            <v-rating
              color="primary"
              background-color="grey"
              empty-icon="star"
              class="pb-1"
              mx-0
              readonly
              dense
              small
              :value="user.reviews | calculateRating"
            ></v-rating>
            <span :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-bold pl-1' : 'font-weight-bold pl-1 body-2'">
              {{ user.reviews | calculateRating }} Stars <span class="caption grey--text">({{ user.reviews.length }} reviews)</span>
            </span>
          </v-card-actions>
        </v-col>
        <v-flex xs12 sm6>
          <v-card-actions class="px-0">
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"/>
            <div v-if="proposal.isFree">
              <v-chip :small="$vuetify.breakpoint.smAndDown" color="#ddf5d3" disabled>
                <span class="body-2 hard-green">
                  Free Legal Advice
                </span>
              </v-chip>
            </div>
            <div v-else>
              <span class="caption grey--text">
                Amount
              </span>
              <v-chip small color="#ddf5d3">
                <span class="body-2 hard-green">
                  {{ proposal.amount | toLocaleString }}
                </span>
              </v-chip>
            </div>
          </v-card-actions>
        </v-flex>
        <v-flex pt-2 :offset-xs1="$vuetify.breakpoint.smAndUp" :xs11="$vuetify.breakpoint.smAndUp" py-0 :pl-2="$vuetify.breakpoint.smAndUp">
          <p v-for="line in proposal.message.split('\n')" :key="line.key" :class="$vuetify.breakpoint.smAndDown ? 'body-2 mb-1' : ''">{{ line }}</p>
        </v-flex>
        <v-col cols="12" class="mt-1 text-right">
          <v-btn
            :loading="isRetracting"
            @click="deleteProposal(proposal._id)"
            color="error"
            outlined
          >Retract Proposal</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <CreateBid
      v-if="user.isLawyer && hasError === false"
      :class="hasSubmitted || hadBid ? 'd-none' : ''" 
      :averageAmount="averageBidAmount"
      :proposalCount="proposals.length"
      :client="client"
      @lawyer-submitted="hasSubmitted = true"
    />
  </div>
  <div v-else>
    <v-sheet class="mt-2 dropShadow pa-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true"/>
        <content-placeholders-text :lines="5"/>
      </content-placeholders>
    </v-sheet>
  </div>
</template>

<script>
import CreateBid from '@/views/lawyer/CreateBid'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import momentFromNow from '@/mixins/momentFromNowMixin'
import calculateRating from '@/mixins/calculateRatingMixin'
import ProposalService from '@/services/ProposalService'

export default {
  components: {
    CreateBid
  },
  mixins: [
    momentFromNow,
    toLocaleString,
    calculateRating
  ],
  props: {
    client: {
      type: Object,
      required: true
    },
    isCancelled: {
      type: Boolean
    }
  },
  data () {
    return {
      isFetching: false,
      isRetracting: false,
      hasSubmitted: false,
      hasError: false
    }
  },
  methods: {
    getLawyerProposal () {
      this.isFetching = true
      this.$store.dispatch('proposals/getUserProposalAtJob', this.$route.params.problemId)
        .catch(err => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    deleteProposal () {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: "You cannot undo this action!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('proposals/retractProposalAtBidders', {
            proposalId: this.proposal._id 
          })
            .then(() => {
              this.$store.dispatch('jobs/setCancelJob', { jobId: this.$route.params.problemId })
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully retracted the selected proposal.',
                confirmButtonColor: this.$vuetify.theme.themes.light.primary,
                onOpen: () => { document.activeElement.blur() }
              })
              this.hasSubmitted = false
            })
            .catch(err => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  filters: {
    truncateMessage: function (value) {
      if (value.length > 200) {
        return value.slice(0, 200) + '... '
      } else {
        return value
      }
    }
  },
  computed: {
    proposals () {
      return this.$store.getters['proposals/laProposals']
    },
    proposal () {
      return this.$store.getters['proposals/singleProposalAtJob']
    },
    averageBidAmount () {
      return this.$store.getters['proposals/averageBidAmount']
    },
    hadBid () {
      return this.$store.getters['proposals/lawyerBidded']
    },
    user () {
      return this.$store.getters['user/user'].user
    }
  },
  created () {
    this.getLawyerProposal()
  }
}
</script>

<style scoped>
  .opacityButton {
    outline-color: dimgrey;
  }
</style>
