<template>
  <v-dialog v-model="show" :max-width="$vuetify.breakpoint.mdAndUp ? '600px' : '300px'" class="ma-0 pa-0">
    <v-sheet>
      <v-layout v-if="$vuetify.breakpoint.mdAndUp" row wrap align-center class="ma-0">
        <v-flex sm10 pa-3>
          <v-layout row wrap>
            <v-flex sm2 pl-2>
              <v-img
                :src="selectedProposal.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                alt="avatar"
                style="border-radius: 8%"
              ></v-img>
            </v-flex>
            <v-flex sm9 pl-2>
              <div class="text-truncate">
                <span style="color: #212f3e" class="text-md-h6 font-weight-medium">{{ selectedProposal.fullName }}</span>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex sm2 pr-3>
          <v-layout row wrap justify-center>
            <v-chip color="#ddf5d3">
              <span v-if="boardInfo.amount != 0" class="text-md-h6 font-weight-medium hard-green">
                {{ boardInfo.amount | toLocaleString }}
              </span>
              <span v-else class="subheading font-weight-medium hard-green">
                Free Legal Advice
              </span>
            </v-chip>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="lightBlack">
          <div class="px-4 pt-3">
            
            <p class="mb-3"><span class="subheading primary--text font-weight-medium">Important: </span>Both parties can agree on how the payment method will be.</p>
          </div>
        </v-flex>
        <v-flex xs12 px-5 py-2>
          <v-layout row wrap>
            <!-- <v-btn
              class="mx-1"
              color="primary"
              large
              text
              @click.stop="newTab('How-To-Pay')"
            >How to Pay</v-btn> -->
            <v-btn
              color="primary"
              large
              text
              @click.stop="newTab('How-It-Works')"
            >How it Works</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              large
              text
              class="mx-1"
              @click.stop="show=false"
            >Cancel</v-btn>
            <v-btn
              large
              depressed
              color="primary"
              @click="createLegalAdviceBoard"
              :loading="isCreating"
            >Hire Lawyer</v-btn>
          </v-layout>
        </v-flex>
      </v-layout>

      <!-- Mobile View -->
      <v-row v-else align="center" justify="center" no-gutters>
        <v-col cols="9" class="pa-0 ">
          <v-row no-gutters>
            <v-col cols="3" class="py-2 pl-2">
              <v-img
                :src="selectedProposal.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                alt="avatar"
                style="border-radius: 8%"
              ></v-img>
            </v-col>
            <v-col cols="9" class="py-2 pl-2">
              <div class="text-truncate">
                <span style="color: #212f3e" class="body-2">{{ selectedProposal.fullName }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-chip color="#ddf5d3" small>
            <span v-if="boardInfo.amount != 0" class="text-md-h6 font-weight-medium hard-green">
              {{ boardInfo.amount | toLocaleString }}
            </span>
            <span v-else class="subheading font-weight-medium hard-green">
              Free Legal Advice
            </span>
          </v-chip>
        </v-col>
        <v-col cols="12">
          <div class="px-4 pt-3">
            <span class="body-2 primary--text font-weight-medium">Important!</span>
            <p class="caption">The lawyer can only answer your question after payment has been sent and verified.</p>
          </div>
        </v-col>
        <v-col cols="12" class="px-2 pb-2">
          <v-btn 
            :loading="isCreating"
            class="primary" 
            @click="createLegalAdviceBoard"
            dark 
            block 
            depressed 
          >
          Hire Lawyer
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-2 pb-2">
          <v-btn block text @click.stop="show = false">Cancel</v-btn>
        </v-col>
      </v-row>
      <!-- Mobile View -->

    </v-sheet>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import LegalAdviceServices from '@/services/LegalAdviceServices'

export default {
  mixins: [
    toLocaleString
  ],
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    selectedProposal: {
      required: true,
      type: Object
    },
    boardInfo: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isCreating: false
    }
  },
  methods: {
    newTab (tabString) {
      let routeData = this.$router.resolve({ name: tabString })
      window.open(routeData.href, '_blank')
    },
    createLegalAdviceBoard () {
      this.isCreating = true
      LegalAdviceServices.createLegalAdviceBoard({
        board: this.boardInfo
      })
        .then(res => {
          this.socket.emit('hire-lawyer', {
            notif: 1,
            notifType: 'Hired Lawyer',
            email: this.boardInfo.email,
            user: this.$store.getters['user/user'].user.fullName,
            imageUrl: this.$store.getters['user/user'].user.imageUrl,
            problemId: this.boardInfo.problem,
            problemTitle: this.boardInfo.problemTitle,
            isAnonymous: this.boardInfo.isAnonymous,
            boardId: res.data._id
          })
          this.$router.push({ name: 'Board', params: { boardId: res.data._id } })
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isCreating = false
        })
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style>

</style>
