<template>
  <div v-if="!isFetching">
    <v-card :class="hasSubmitted || hadBid || !user.isLawyer ? 'mt-2' : 'd-none'">
      <v-row align="center">
        <v-col v-if="proposals.length == 0" class="text-md-subtitle-1 text-center pa-2" cols="12">There are no bids yet.</v-col>
        <v-col v-else>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="8">
              <span :class="!$vuetify.breakpoint.mdAndUp ? 'caption' : ''">{{ proposals.length }} lawyer(s) are bidding on this task.</span>
            </v-col>
            <v-col cols="3">
              <span v-if="!$vuetify.breakpoint.smAndDown" class="grey--text">Average bid: <span class="primary--text font-weight-medium">{{ averageBidAmount | toLocaleString }}</span></span>
              <div v-else class="text-center">
                <span class="mb-0 caption primary--text font-weight-medium">{{ averageBidAmount | toLocaleString }}</span><br />
                <span class="mb-0 caption grey--text">Average bid</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-for="(proposal, index) in proposals" :key="index">
      <v-divider></v-divider>
        <v-row align="center" class="pa-3" no-gutters>
          <v-flex xs2 sm1 pa-0>
            <v-img
              :src="proposal.lawyer.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
              alt="avatar"
              style="border-radius: 8%"
            ></v-img>
          </v-flex>
          <v-flex v-if="proposals" xs10 sm5 pl-2>
            <span class="text-md-body-2 font-weight-medium viewMorePointer" @click="redirectProfile(proposal.lawyer._id)">{{ proposal.lawyer.fullName }}</span>
            <v-card-actions v-if="proposal.lawyer.reviews.length > 0" class="pa-0">
              <v-rating
                readonly
                dense
                small
                color="primary"
                background-color="grey"
                empty-icon="star"
                mx-0
                :value="proposal.lawyer.reviews | calculateRating"
              ></v-rating>
              <span class="font-weight-bold pl-1">
                {{ proposal.lawyer.reviews | calculateRating }} Stars <span class="caption grey--text">({{ proposal.lawyer.reviews.length }} reviews)</span>
              </span>
            </v-card-actions>
          </v-flex>
          <v-flex xs12 sm6>
            <v-card-actions class="px-0">
              <v-spacer v-if="$vuetify.breakpoint.smAndUp"/>
              <div v-if="proposal.isFree">
                <v-chip color="#ddf5d3">
                  <span class="body-2 hard-green">
                    Free Legal Advice
                  </span>
                </v-chip>
              </div>
              <div v-else>
                <span class="caption grey--text">
                  Amount
                </span>
                <v-chip small color="#ddf5d3">
                  <span class="body-2 hard-green">
                    {{ proposal.amount | toLocaleString}}
                  </span>
                </v-chip>
              </div>
            </v-card-actions>
          </v-flex>
          <v-flex :offset-xs1="$vuetify.breakpoint.smAndUp" :xs10="$vuetify.breakpoint.smAndUp" py-0 :pl-2="$vuetify.breakpoint.smAndUp">
            <div :id="'message' + index" class="d-none">
              <p v-for="line in proposal.message.split('\n')" :key="line.key" class="body-2">{{ line }}</p>
            </div>
            <span :id="'truncatedMessage' + index" class="body-2">{{ proposal.message | truncateMessage }}</span>
            <span v-if="proposal.message.length > 200" :id="'btn' + index" class="font-weight-bold viewMorePointer" @click="viewMore(index)">View More</span>
          </v-flex>
          <v-flex xs12 mt-3>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="user._id === proposal.lawyer._id"
                text
                color="error"
                :loading="isRetracting"
                @click="deleteProposal(proposal._id, index)"
              >Retract Proposal</v-btn>
              <v-btn
                v-else
                text
                color="primary"
                :to="{ name: 'ViewLawyer', params: { lawyerId: proposal.lawyer._id }}"
              >View Profile</v-btn>
              <v-btn
                v-if="!user.isLawyer"
                depressed
                color="primary"
                @click="selectToHireLawyer(proposal)"
              >Hire this Lawyer</v-btn>
            </v-card-actions>
          </v-flex>
        </v-row>
      </div>
    </v-card>
    <CreateBid v-if="user.isLawyer && hasError === false" :class="hasSubmitted || hadBid ? 'd-none' : ''"  @lawyer-submitted="hasSubmitted = true" :averageAmount="averageBidAmount" :lawyerCount="proposals.length"/>
    <HireLawyer :visible="hireLawyerModal" :boardInfo="boardInfo" :selectedProposal="selectedProposal" @close="hireLawyerModal = false"/>
    <ViewLawyer :visible="showLawyerProfile" @close="showLawyerProfile = false"/>
  </div>
  <div v-else>
    <v-card class="mt-2 dropShadow pa-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true"/>
        <content-placeholders-text :lines="5"/>
      </content-placeholders>
    </v-card>
  </div>
</template>

<script>
import HireLawyer from '@/components/ProposalComponents/HireLawyerModal'
import ViewLawyer from '@/components/ProposalComponents/ViewLawyer'
import CreateBid from '@/views/lawyer/CreateBid'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import momentFromNow from '@/mixins/momentFromNowMixin'
import calculateRating from '@/mixins/calculateRatingMixin'

export default {
  props: {
    problemTitle: {
      type: String,
      required: true
    },
    isAnonymous: {
      type: Boolean
    }
  },
  components: {
    CreateBid,
    HireLawyer,
    ViewLawyer
  },
  mixins: [
    momentFromNow,
    toLocaleString,
    calculateRating
  ],
  data () {
    return {
      isFetching: false,
      isRetracting: false,
      hasSubmitted: false,
      hasError: false,
      showLawyerProfile: false,
      hireLawyerModal: false,
      selectedProposal: {},
      boardInfo: {}
    }
  },
  methods: {
    getProposal () {
      this.isFetching = true
      this.$store.dispatch('proposals/GET_LA_PROPOSALS', this.$route.params.problemId)
        .catch(() => {
          this.hasError = true
          this.$swal({
            type: 'error',
            text: 'Something went wrong when fetching the bidders!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    viewMore (index) {
      if (document.getElementById('btn' + index).innerText === 'View More') {
        document.getElementById('btn' + index).innerText = 'View Less'
        document.getElementById('message' + index).className = ''
        document.getElementById('truncatedMessage' + index).className = 'd-none'
      } else {
        document.getElementById('btn' + index).innerText = 'View More'
        document.getElementById('message' + index).className = 'd-none'
        document.getElementById('truncatedMessage' + index).className = ''
      }
    },
    selectToHireLawyer (proposal) {
      let board = {
        amount: proposal.amount,
        followUps: proposal.followUps,
        proposal: proposal._id,
        problem: this.$route.params.problemId,
        lawyer: proposal.lawyer._id,
        email: proposal.lawyer.email,
        problemTitle: this.problemTitle,
        isAnonymous: this.isAnonymous
      }

      let selectedProposalInfo = {
        followUps: proposal.followUps,
        fullName: proposal.lawyer.fullName,
        imageUrl: proposal.lawyer.imageUrl
      }

      this.selectedProposal = Object.assign(selectedProposalInfo)
      this.boardInfo = Object.assign(board)
      this.hireLawyerModal = true
    },
    deleteProposal (proposalId, index) {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: "You will not revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          let content = { proposalId, index, fromBidders: true }
          return this.$store.dispatch('proposals/DELETE_USER_PROPOSAL', content)
            .then(() => {
              this.$store.dispatch('jobs/setCancelJob', { jobId: this.$route.params.problemId })
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully retracted the selected proposal.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
              this.hasSubmitted = false
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    redirectProfile (profile) {
      this.$router.push({ name: 'ViewLawyer', params: { lawyerId: profile } })
    }
  },
  filters: {
    truncateMessage: function (value) {
      if (value.length > 200) {
        return value.slice(0, 200) + '... '
      } else {
        return value
      }
    }
  },
  computed: {
    proposals () {
      return this.$store.getters['proposals/laProposals']
    },
    averageBidAmount () {
      return this.$store.getters['proposals/averageBidAmount']
    },
    hadBid () {
      return this.$store.getters['proposals/lawyerBidded']
    },
    user () {
      return this.$store.getters['user/user'].user
    }
  },
  // created () {
  //   this.getProposal()
  // }
}
</script>
